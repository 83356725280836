import { useMutation, useQueryClient } from '@tanstack/react-query';

import API from '../api';
import { AUTHENTICATION_USER_PROFILE_PATH } from './use-query-user-profile';

export const signOut = async () => await API.post('/authentication/sign-out');

const useSignOut = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: signOut,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [AUTHENTICATION_USER_PROFILE_PATH],
      });
    },
  });
};

export default useSignOut;

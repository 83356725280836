import { changeI18nLanguage, type I18nTranslation, useExtendI18nTranslation } from '@bluemarker/core';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
  SidebarUser,
  Skeleton,
  useSidebarContext,
} from '@bluemarker/ui';
import { LanguagesIcon, UnitedKingdomFlagIcon, VietnamFlagIcon } from '@bluemarker/ui/icons';
import { useState } from 'react';

import useQueryUserProfile from '@/apis/authentication/use-query-user-profile';

const SidebarUserDropdownSkeleton = () => (
  <div className="!mt-4 flex items-center gap-2">
    <Skeleton className="h-10 w-10 rounded-full" />
    <div className="flex-1 space-y-1">
      <Skeleton className="h-4 w-full" />
      <Skeleton className="h-4 w-2/3" />
    </div>
  </div>
);

const TRANSLATION: I18nTranslation = {
  en: {
    greeting: 'Greeting!',
    languagePreference: 'Language preference',
    vi: 'Vietnamese',
    en: 'English',
  },
  vi: {
    greeting: 'Xin chào!',
    languagePreference: 'Tuỳ chọn ngôn ngữ',
    vi: 'Tiếng Việt',
    en: 'Tiếng Anh',
  },
};

const SidebarUserDropdown = () => {
  const { t } = useExtendI18nTranslation(TRANSLATION);
  const { data: user } = useQueryUserProfile();
  const [open, setOpen] = useState(false);
  const { isCollapsed } = useSidebarContext();

  return user ? (
    <DropdownMenu open={open} onOpenChange={setOpen}>
      <DropdownMenuTrigger asChild>
        <SidebarUser name={user.name} subInfo={t('greeting')} avatarUrl={user.avatarUrl} isActive={open} />
      </DropdownMenuTrigger>
      <DropdownMenuContent
        side={isCollapsed ? 'right' : 'bottom'}
        align={isCollapsed ? 'start' : 'center'}
        alignOffset={isCollapsed ? -8 : undefined}
        className="w-60"
      >
        <div className="text-sm px-2 pt-2 pb-1">
          <h3 className="font-bold truncate">{user.name}</h3>
          <p className="text-neutral-5 truncate">{user.email}</p>
        </div>

        <DropdownMenuSeparator />

        <DropdownMenuSub>
          <DropdownMenuSubTrigger>
            <LanguagesIcon className="w-4 h-4 mr-2" />
            {t('languagePreference')}
          </DropdownMenuSubTrigger>
          <DropdownMenuPortal>
            <DropdownMenuSubContent>
              <DropdownMenuItem onClick={() => changeI18nLanguage('vi')}>
                <VietnamFlagIcon className="w-4 h-4 mr-2" />
                {t('vi')}
              </DropdownMenuItem>
              <DropdownMenuItem onClick={() => changeI18nLanguage('en')}>
                <UnitedKingdomFlagIcon className="w-4 h-4 mr-2" />
                {t('en')}
              </DropdownMenuItem>
            </DropdownMenuSubContent>
          </DropdownMenuPortal>
        </DropdownMenuSub>
      </DropdownMenuContent>
    </DropdownMenu>
  ) : (
    <SidebarUserDropdownSkeleton />
  );
};

export default SidebarUserDropdown;

import { type I18nTranslation, useExtendI18nTranslation } from '@bluemarker/core';
import { Tooltip, TooltipTrigger } from '@radix-ui/react-tooltip';

import { ChevronLeftIcon } from '../../icons';
import { cn } from '../../utils';
import { Button, TooltipContent } from '../base';
import { useSidebarContext } from './context';

export type SidebarCollapseButtonProps = {
  className?: string;
};

const TRANSLATION: I18nTranslation = {
  en: {
    collapse: 'Collapse',
  },
  vi: {
    collapse: 'Thu gọn',
  },
};

const CollapseButton = ({ className }: SidebarCollapseButtonProps) => {
  const { isCollapsed, setIsCollapsed } = useSidebarContext();
  const { t } = useExtendI18nTranslation(TRANSLATION);

  const toggleCollapse = () => setIsCollapsed((prev) => !prev);

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Button
          className={cn(
            'absolute transition-all right-0 translate-x-1/2 top-[1.875rem] z-20 max-w-9 overflow-hidden opacity-100 text-neutral-5 hover:text-neutral-7 shrink-0',
            isCollapsed && 'max-w-0 opacity-0',
            className
          )}
          variant="outline"
          size="icon"
          onClick={toggleCollapse}
        >
          <ChevronLeftIcon className="h-4 w-4" />
        </Button>
      </TooltipTrigger>
      <TooltipContent side="right">{t('collapse')}</TooltipContent>
    </Tooltip>
  );
};

export default CollapseButton;

import type { Dispatch, SetStateAction } from 'react';

import { createContext } from '../../utils';

export type SidebarContext = {
  isCollapsed: boolean;
  setIsCollapsed: Dispatch<SetStateAction<boolean>>;
};

const [useSidebarContext, SidebarContextProvider] = createContext<SidebarContext>();

export { SidebarContextProvider, useSidebarContext };

import { lazy } from 'react';
import type { RouteObject } from 'react-router-dom';

const UsersPage = lazy(() => import('./page'));

export const UserRoutes: RouteObject[] = [
  {
    path: '/user',
    Component: UsersPage,
  },
  // define more routes here ...
];

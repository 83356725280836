import { type I18nTranslation, useExtendI18nTranslation } from '@bluemarker/core';
import { Separator, SidebarCollapseButton, SidebarContent, SidebarLogo, SidebarNav } from '@bluemarker/ui';
import { Building2Icon, LogOutIcon, UsersIcon } from '@bluemarker/ui/icons';
import { Link, useLocation } from 'react-router-dom';

import useSignOut from '@/apis/authentication/use-sign-out';

import SidebarUserDropdown from './sidebar-user-dropdown';

const TRANSLATION: I18nTranslation = {
  en: {
    dashboard: 'Dashboard',
    organizations: 'Organizations',
    users: 'Users',
    signOut: 'Sign out',
    help: 'Help',
  },
  vi: {
    dashboard: 'Trang chủ',
    organizations: 'Doanh nghiệp',
    users: 'Người dùng',
    help: 'Hỗ trợ',
    signOut: 'Đăng xuất',
  },
};

const AdminSidebar = () => {
  const { pathname } = useLocation();

  const { t } = useExtendI18nTranslation(TRANSLATION);

  const { mutate: signOut } = useSignOut();

  return (
    <SidebarContent>
      <SidebarCollapseButton />
      <Link to="/">
        <SidebarLogo bluemarkerPlatform="Admin Platform" />
      </Link>

      <SidebarUserDropdown />

      <Separator className="mb-4" />

      <div className="flex flex-col flex-1 !mt-0">
        <div className="flex-1 space-y-2">
          {/* <SidebarNav label={t('dashboard')} Icon={LayoutDashboardIcon} active={pathname === '/'} href="/" /> */}

          <SidebarNav label={t('users')} Icon={UsersIcon} active={pathname.startsWith('/user')} href="/user" />

          <SidebarNav
            label={t('organizations')}
            Icon={Building2Icon}
            active={pathname.startsWith('/organization')}
            href="/organization"
          />
        </div>
        <div className="space-y-2">
          <SidebarNav
            className="text-danger-4 hover:text-danger-4 hover:bg-danger-4/10"
            label={t('signOut')}
            Icon={LogOutIcon}
            active={false}
            handler={signOut}
          />
        </div>
      </div>
    </SidebarContent>
  );
};

export default AdminSidebar;

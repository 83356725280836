import { type ElementRef, forwardRef, type ReactNode } from 'react';

import { cn, generateAvatarText } from '../../utils';
import { Avatar, AvatarFallback, AvatarImage, Button, type ButtonProps } from '../base';
import { useSidebarContext } from './context';

export type SidebarUserProps = {
  className?: string;
  name: string;
  subInfo?: ReactNode;
  avatarUrl?: string;
  isActive?: boolean;
} & ButtonProps;

const SidebarUser = forwardRef<ElementRef<typeof Button>, SidebarUserProps>(
  ({ className, name, avatarUrl, subInfo, isActive = false, ...buttonProps }, ref) => {
    const { isCollapsed } = useSidebarContext();

    return (
      <Button
        variant={isActive ? 'secondary' : 'ghost'}
        className={cn(
          'h-fit flex items-center justify-start gap-2 w-full px-2 text-left',
          isCollapsed ? 'rounded-full p-0 w-11 h-11 mb-6 -mt-0.5 mx-auto' : 'my-2',
          className
        )}
        {...buttonProps}
        ref={ref}
      >
        <Avatar className="w-11 h-11">
          <AvatarImage src={avatarUrl} />
          <AvatarFallback>{generateAvatarText(name)}</AvatarFallback>
        </Avatar>
        <div
          className={cn(
            'transition-all ease-in-out overflow-hidden duration-300 truncate',
            isCollapsed ? 'animate-out fade-out-0 max-h-0' : 'animate animate-in fade-in-0'
          )}
        >
          <h3 className="font-bold truncate">{name}</h3>
          {typeof subInfo === 'string' ? <p className="text-neutral-5 truncate">{subInfo}</p> : subInfo}
        </div>
      </Button>
    );
  }
);

SidebarUser.displayName = 'SidebarUser';

export default SidebarUser;

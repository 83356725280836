export const generateAvatarText = (text: string): string => {
  const words = text.split(' ');

  if (words.length === 1) {
    return words[0]?.substring(0, 2).toLocaleUpperCase() ?? '';
  }

  if (words.length >= 2) {
    return `${words[0]?.[0]}${words[1]?.[0]}`.toLocaleUpperCase();
  }

  return '';
};

import { locale } from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/vi';
import { type PropsWithChildren, useEffect } from 'react';

import { useExtendI18nTranslation } from '../i18next';

const DayjsLocaleProvider = ({ children }: PropsWithChildren) => {
  const {
    i18n: { language },
  } = useExtendI18nTranslation();

  useEffect(() => {
    locale(language);
  }, [language]);

  return children;
};

export default DayjsLocaleProvider;

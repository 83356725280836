import type { PropsWithChildren } from 'react';

import { cn } from '../../utils';
import { useSidebarContext } from './context';

export type SidebarContentProps = PropsWithChildren;

const Content = ({ children }: SidebarContentProps) => {
  const { isCollapsed } = useSidebarContext();

  return (
    <div>
      <nav
        className={cn(
          'relative w-[calc(16rem+1px)] min-w-[calc(16rem+1px)] border-r border-neutral-2 h-screen transition-all duration-300 ease-in-out px-6 pb-6 flex flex-col bg-neutral-0',
          isCollapsed && 'w-[calc(3.75rem+1px)] min-w-[calc(3.75rem+1px)] px-2 py-6'
        )}
      >
        {children}
      </nav>
    </div>
  );
};

export default Content;

import { lazy } from 'react';
import type { RouteObject } from 'react-router-dom';

const DashboardPage = lazy(() => import('./page'));

export const DashboardRoutes: RouteObject[] = [
  {
    path: '/',
    Component: DashboardPage,
  },
  // define more routes here ...
];

import { configure, done, start } from 'nprogress';
import 'nprogress/nprogress.css';
import { useEffect } from 'react';

import './top-loader.css';

const TopLoader = () => {
  useEffect(() => {
    configure({ showSpinner: false });
    start();

    return () => {
      done();
    };
  }, []);

  return <></>;
};

export default TopLoader;

import type { PropsWithChildren } from 'react';
import { Toaster } from 'sonner';

export type ToastProviderProps = PropsWithChildren;

const ToastProvider = ({ children }: ToastProviderProps) => {
  return (
    <>
      <Toaster
        toastOptions={{
          duration: 3000,
          style: { fontFamily: 'Be Vietnam Pro' },
        }}
      />
      {children}
    </>
  );
};

export default ToastProvider;
